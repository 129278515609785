import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Modal, Checkbox } from 'antd';
import Mispichoshelper from "../../../utils/Mispichoshelper";
import { set } from "react-ga";


const confirm = Modal.confirm;


export default function Refund({ compra, hideReembolso, showReembolso, refrescarCompra }) {

    const [loading, setLoading] = useState(false)
    const [motivSelect, setMotiv] = useState()
    const [observaciones, setObs] = useState("")
    const [motivos, setMotivos] = useState([])
    const [notMakeRefund, setNotMakeRefund] = useState(false)

    useEffect(() => {
        Mispichoshelper.getMotivosCompraCancelaciones().then(response => setMotivos(response.payload));
    }, [])

    const cancelaCompra = () => {

        var params = {
            observaciones: observaciones,
            motivo_id: motivSelect,
            'compra_id': compra.compra_id,
            not_make_refund: notMakeRefund,
        };
        Mispichoshelper.postCancelaCompra(params).then(informaResponse);
    }

    const informaResponse = (response) => {
        refrescarCompra(compra.compra_id);
        if (response && response.status) {
            confirm({
                title: response.payload.message,
                content: 'Datos guardados satisfactoriamente',
                onOk: () => hideReembolso('reembolso', false)
            });
        } else if (response && response.payload && response.payload.message) {
            Modal.error({
                title: "Ocurrio un Error",
                content: response.payload.message,
            });
        } else {
            Modal.error({
                title: "Ocurrio un Error",
                content: 'No pudimos cancelar la compra',
            });
        }
    }

    const handleOk = () => {
        if (observaciones !== "") {
            confirm({
                title: 'Confirma la cancelación de la compra?',
                content: '',
                onOk: () => cancelaCompra()
            });
        } else {
            Modal.error({
                title: "Por favor completa el campo de observaciones",
            });
        }
    }

    const generateTextAlert = () => {
        if (compra.compra_medio_pago === 'tarjeta') {

            if (notMakeRefund) {
                return "Está seleccionada la opción de no generar reembolso." +
                    "No se generará reembolso por el monto de $" + compra.compra_total + " sobre la compra del Cliente " + compra.compra_cliente + "." +
                    "Esto es para casos especiales y debes tener los permisos correspondientes para hacerlo."
            }

            return "Se generera un reembolso total de $" + compra.compra_total + " sobre la compra del Cliente " + compra.compra_cliente + ", el mismo se vera reflejado es su proximo resumen."
        }
        return "La compra fue realizada en efectivo, no hay nada más que hacer Rick."
    }

    return (
        <Modal
            title="Advertencia"
            visible={showReembolso}
            onOk={handleOk}
            onCancel={() => hideReembolso('reembolso', false)}
        >
            <Row style={{ margin: 10 }}>
                <Col span={24}>
                    <Alert
                        message="Se Cancelará esta compra."
                        description={generateTextAlert()}
                        type={notMakeRefund ? "error" : "warning"}
                    />
                </Col>
            </Row>
            {compra.compra_medio_pago === 'tarjeta' &&
                <Row style={{ margin: 10 }}>
                    <Col span={24}>
                        <Checkbox
                            checked={notMakeRefund}
                            onChange={(e) => setNotMakeRefund(e.target.checked)}
                        >
                            No generar reembolso
                        </Checkbox>
                    </Col>
                </Row>
            }
            <Row style={{ margin: 10 }}>
                <Col span={24}>
                    <select
                        className='w100 MT1 '
                        onChange={(evt) => setMotiv(parseInt(evt.target.value))}
                        placeholder="Elige un motivo"
                        required
                    >
                        <option value=''>Seleccionar</option>
                        {motivos.map((item, idx) => (
                            <option key={idx} value={item.id}>{item.nombre}</option>
                        ))}
                    </select>

                    <textarea style={{ width: '100%' }} rows="4" maxLength="140" onChange={(evt) => setObs(evt.target.value)} className="MT1">

                    </textarea>
                </Col>
            </Row>
        </Modal>
    )
}

